module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/404","/vacancies","/job-alerts","/sitemap"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-0","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"AccroPress"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://31544eb084ae44539700cd4d7e0ca55d@sentry.accropress.com/5","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jobs at Healthspan","short_name":"Jobs at Healthspan","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"static/img/apple-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98b994217011d5ca9a6c0332891f2e4a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
